.botones{
    background-color: #408AB8;
    color: #fff;
    font-weight: 500;
    padding: 10px;
    border-color: #408AB8;
    border-radius: 10px;
    width: 18%;
}
.botones:hover{
    background-color: #C7DA53;
    color: #fff;
    font-weight: 500;
    padding: 10px;
    border-color: #C7DA53;
    border-radius: 10px;
}
.botonoutline{
    border-color:#408AB8;
    color: #408AB8;
   
    text-transform: none;
    padding: 10px;
    width: 15%;
    font-weight: 500;
    border-radius: 10px;
    border-width: 2px;
}
.botonoutline:hover{
    border-color:#408AB8;
    color: #fff;
    background: #408AB8;
   
    text-transform: none;
    padding: 10px;
    width: 15%;
    font-weight: 500;
    border-radius: 10px;
    border-width: 2px;
}

.inputdata div{
    border-radius: 10px;
    height: 3em;
    width: 100%;
}
.inputdata label{
    font-size: 15px;
}
.title{
    font-size: 16px;
    font-weight: 700;
   
}
.boton-deds{
    width: 100%;
    height: 3em;
    border-radius: 10px;
    background: #408AB8;
    color: #fff;
    
}
.boton-deds:hover{
    background: #4f9ac9;
    color: #fff;
    border-color: #4f9ac9;
}
.button-deds{
    width: 15%;
    margin-top: 12px;
}
.boton-deudas{
    background: #FA0022;
    color: #fff;
    width: 100%;
    height: 3em;
    border-radius: 10px;

}
.boton-deudas:hover{
    background: #e93b52;
    color: #fff;
    width: 100%;
    height: 3em;
    border-radius: 10px;
}
.button-deudas{
     width: 15%;
     margin-top: 12px;
}
.MuiList-root .MuiButton-root{
    color: #408AB8;
}
.css-135hyr2-MuiButtonBase-root-MuiButton-root:hover{
    color: #fff;
    background: #408AB8;
    border-radius: 10px;
}
.css-vklcgy-MuiListSubheader-root{
    background: #408AB8;
    color: #fff;
    border-radius: 10px;
}
.css-18mlny8{
    background: #e7e7e7;
}
.css-xik97x-MuiTableCell-root, .css-14mcqjm-MuiTableCell-root,.css-k60gn0-MuiTableCell-root{
    background: #408AB8;
    color: #fff;
}
.css-zmjyei-MuiTypography-root{
    color: #fff;
   
}

.css-1o6a83x-MuiTypography-root{
    color: #6f6f6f;
    /*text-align: center;*/
    font-weight: 600;
    font-size: 16px;
}
.css-lmgw5n-MuiButtonBase-root-MuiButton-root:hover{
    background: #408AB8;
    color: #fff;
}
.css-16pz6kq-MuiButtonBase-root-MuiButton-root:hover{
    background: #408AB8;
    color: #fff;
}
.css-19hsryy-MuiAvatar-root{
    background: #C7DA53;
}
.css-1b3o33l-MuiButtonBase-root-MuiButton-root{
    background: #C7DA53;
    border-color: #C7DA53;
    color: #fff;
}
.css-1b3o33l-MuiButtonBase-root-MuiButton-root:hover{
    background: #C7DA53;
    border-color: #C7DA53;
    color: #fff;
}

.css-4uteg7-MuiGrid-root{
    justify-content: flex-start;
}
.css-1vkj9y4-MuiSvgIcon-root{
    color: #fff;
} 
.css-1ydld38-MuiSvgIcon-root{
    color: #fff;
}

.grillabotonRes{
      display: none;
}
tr:nth-child(odd) {
    background-color: #eeeeee;
   
}

@media only screen and (max-width : 991px) {
    .grillaboton{
        display: none;
    }
    .grillabotonRes{
        display: block;
    }
    .grillabotonRes .botones {
        background-color: #408AB8;
        color: #fff;
        font-weight: 500;
        padding: 10px;
        border-color: #408AB8;
        border-radius: 10px;
        /* width: 15%; */
        width: 65%;
        height: 4em;
    }
    .botones:hover{
        background-color: #C7DA53;
        color: #fff;
        font-weight: 500;
        padding: 10px;
        border-color: #C7DA53;
        border-radius: 10px;
        width: 65%;
        height: 4em;
    }
    .botones{
    background-color: #408AB8;
    color: #fff;
    font-weight: 500;
    padding: 10px;
    border-color: #408AB8;
    border-radius: 10px;
    /* width: 15%; */
    width: 45%;
    height: 4em;
    }
   
    .botonoutline {
        border-color: #408AB8;
        color: #408AB8;
        text-transform: none;
        padding: 10px;
        width: 45%;
        font-weight: 500;
        border-radius: 10px;
        border-width: 2px;
        height: 4em;
    }
    .botonoutline:hover{
        border-color:#408AB8;
        color: #fff;
        background: #408AB8;
       
        text-transform: none;
        padding: 10px;
        width: 45%;
        height: 4em;
        font-weight: 500;
        border-radius: 10px;
        border-width: 2px;
    }
    .button-deds{
        width: 56%;
        margin-top: 12px;
    }
    .boton-deds{
        width: 100%;
        height: 3em;
        border-radius: 10px;
        background: #408AB8;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        
    }
    .button-deudas{
        width: 56%;
        margin-top: 12px;
        margin-bottom: 6px;
   }
   .boton-deudas{
    background: #FA0022;
    color: #fff;
    width: 100%;
    height: 3em;
    border-radius: 10px;
    font-size: 17px;

}
}
/*tablet*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px )  {
    .botones {
        background-color: #408AB8;
        color: #fff;
        font-weight: 500;
        padding: 10px;
        border-color: #408AB8;
        border-radius: 10px;
        width: auto;
        height: 4em;
    }
    .botonoutline {
        border-color: #408AB8;
        color: #408AB8;
        text-transform: none;
        padding: 10px;
        width: auto;
        font-weight: 500;
        border-radius: 10px;
        border-width: 2px;
        height: 4em;
    }
    .button-deds {
        width: 30%;
        margin-top: 12px;
    }
    .button-deudas{
        width: 30%;
        margin-top: 12px;
        margin-bottom: 6px;
   }
}


@media (min-height: 800px) {
    div[class*="makeStyles-container-"] {
        height: 600px !important;
    }    
}